<template>
  <hyperion v-if="$store.state.user.roles.includes('Member')"/>
  <pre-hyperion v-else/>
</template>

<script>
import Hyperion from "./Hyperion";
import PreHyperion from "./PreHyperion";

export default {
  components: {
    PreHyperion,
    Hyperion
  },
  async created() {

  }
}
</script>