<template>
  <div class="role-applications">
    <progress-linear v-if="loading"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">{{ __('common.total') }}:</div>
        <div class="control-bar__info-value">{{ pagination.total }}</div>
      </div>
      <div class="control-bar__search ml" v-if="!onlyCurrentUser">
        <select-reference
            v-model="userId"
            :endpoint="'/community/user'"
            :field-icon="'avatar'"
            :field-title="'userName'"
            :nothing-selected-message="__('filterByUser')"
            :hide-info="true"
            @input="refresh"
        />
      </div>
      <div class="control-bar__buttons">
        <div class="control-bar__button control-bar__button--with-text" :title="'Bewerben'" v-if="canApply"
             @click="addRoleApplication"
             v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/add.svg"
               alt="bewerben"/>
          Bewerben
        </div>
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
      </div>
    </div>
    <div>
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <data-table
          :records="roleApplications"
          :fields="dataTableFields"
          :record-actions="recordActions"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :loading="loading"
          @sort="sort"
          @editRoleApplication="editRoleApplication"
          @resolveRoleApplication="resolveRoleApplication"
      />
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
    </div>
    <edit-role-application ref="editRoleApplication" :role="role" @created="refresh" @updated="refresh"/>
    <resolve-role-application ref="resolveRoleApplication" :role="role" @created="refresh" @updated="refresh(true)"/>
  </div>
</template>

<script>
import apiSecured from '../../api/secured';
import DataTable from '../../components/DataTable';
import { notificationsMixin } from '../../mixins/notifications';
import { localizationMixin } from '../../mixins/localization';
import { paginationMixin } from "../../mixins/pagination";
import { userMixin } from "../../mixins/user";
import EditRoleApplication from "./EditRoleApplication";
import ResolveRoleApplication from "./ResolveRoleApplication";

const renderStatus = (value) => {
  switch (value) {
    case 'open':
      return '<span class="ribbon info">offen</span>'

    case 'accepted':
      return '<span class="ribbon success">angenommen</span>'

    case 'declined':
      return '<span class="ribbon error">abgelehnt</span>'

    default:
      return '<span class="ribbon warning">value</span>'
  }
}

export default {
  mixins: [notificationsMixin, localizationMixin, paginationMixin, userMixin],
  components: {
    ResolveRoleApplication,
    EditRoleApplication,
    DataTable,
  },
  props: ['role', 'onlyCurrentUser'],
  data: () => ({
    userId: null,
    loading: false,
    roleApplications: [],
    dataTableFields: [],
    recordActions: [],
    ownActiveRoleApplications: [],
  }),
  watch: {
    userId() {
      this.refresh();
    },
  },
  computed: {
    canApply() {
      if (this.role && this.role.members && this.role.members.some(member => member.userName === this.$store.state.user.username)) {
        return false;
      }
      return this.ownActiveRoleApplications.length <= 0;

    }
  },
  methods: {
    addRoleApplication() {
      this.$refs.editRoleApplication.showCreate({ roleId: this.role.id });
    },
    editRoleApplication(roleApplication) {
      this.$refs.editRoleApplication.showEdit(roleApplication);
    },
    resolveRoleApplication(roleApplication) {
      this.$refs.resolveRoleApplication.showEdit(roleApplication);
    },
    refreshOwn() {
      this.loading = true;
      const params = {
        userIds: this.$store.state.user.id,
        roleIds: this.role.id,
        state: 'open',
      }
      apiSecured.get('/identity/roleapplication', { params }).then(async (res) => {
        this.ownActiveRoleApplications = res.data;
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error);
      });
    },
    refresh(bubbleEvent = false) {
      this.loading = true;
      let params = this.getPaginationParams();
      params.userIds = this.onlyCurrentUser ? this.$store.state.user.id : this.userId;
      params.roleIds = this.role.id;
      apiSecured.get('/identity/roleapplication', { params }).then(async (res) => {
        this.roleApplications = res.data;
        this.roleApplications = await this.enrichRecords(res.data);
        this.updatePagination(res);
        this.loading = false;
        this.refreshOwn();
        if(bubbleEvent === true) {
          this.$emit('resolved');
        }
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error);
      });
    },
    async enrichRecords(roleApplications) {
      const userIds = roleApplications.map(roleApplication => roleApplication.userId);
      if (userIds.length > 0) {
        await apiSecured.get(`/community/user/(${userIds.join(',')})`).then((res) => {
          roleApplications = roleApplications.map(roleApplication => {
            const user = res.data.find(user => user.id === roleApplication.userId);
            roleApplication.userAvatar = 'https://cdn.hyperion-corporation.de/ui/placeholder_avatar.png';
            if (user) {
              if (user.avatar) {
                roleApplication.userAvatar = user.avatar;
              }
              roleApplication.userName = user.userName;
            }
            return roleApplication;
          });
        }).catch((error) => {
          this.showErrorNotifications(error)
        });
      }
      return roleApplications;
    },
  },
  created() {
    this.dataTableFields = [
      { name: "userAvatar", title: 'Avatar', type: "image", disableSorting: true },
      { name: "userName", title: this.__('entity.common.name'), type: "default", disableSorting: true },
      { name: "state", title: 'Status', type: "custom", render: renderStatus },
      { name: "createdAt", title: this.__('entity.common.created_at'), type: "datetime" },
      { name: "updatedAt", title: this.__('entity.common.updated_at'), type: "datetime" }
    ];
    this.recordActions = [
      {
        title: 'Bewerbung bearbeiten',
        icon: 'pencil',
        event: 'editRoleApplication',
        vif: (record) => record.userId === this.$store.state.user.id
      }
    ]
    const user = this.$store.state.user;
    if (user) {
      if (user.roles.includes('Administrator') || this.role.primeId === user.id || this.role.viceId === user.id) {
        this.recordActions.push({
          title: 'Bewerbung bearbeiten',
          icon: 'pencil',
          event: 'resolveRoleApplication'
        });
      }
    }
    this.refresh();
  }
}
</script>
