<template>
  <div class="resolve-role-application">
    <my-dialog :show="show" @close="close" max-width="800px">
      <div class="card">
        <div class="card__header">
          <div class="card__title">Bewerbung bei {{ role ? role.name : '-' }}</div>
          <div class="card__button" v-slashes @click="close"><img
              src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/></div>
        </div>
        <div class="card__content" v-if="record">
          <progress-linear v-if="loading"/>
          <div class="tile" v-if="record.role && record.role.description"
               v-html="record.role.description ? record.role.description : '--- keine Beschreibung ---'"></div>
          <div class="layout layout--wrap">
            <div class="flex xs4 mt">
              <div class="tile mt">
                <div class="text--teal text--orbitron">Benutzer</div>
                <div
                    class="org-part__member"
                    :class="{'org-part__member--no-action': true, 'org-part__member--disabled': user.lockoutEnabled}"
                    :style="getAvatarStyle(user)"
                >
                  <div class="org-part__member-avatar"></div>
                  <div class="org-part__member-username">{{ user.userName }}</div>
                </div>
              </div>
            </div>
            <div class="flex xs4 mt">
              <div class="tile mt">
                <div class="text--teal text--orbitron">Rollen</div>
                <ul class="futuristic">
                  <li v-for="roleName in user.roleNames">{{ roleName }}</li>
                </ul>
              </div>
            </div>
            <div class="flex xs4 mt">
              <div class="tile mt">
                <div class="text--teal text--orbitron">Spieler</div>
                <div class="org-part__members mt">
                  <div
                      class="org-part__member"
                      :class="{'org-part__member--no-action': true }"
                      v-for="player in user.players"
                      :key="`player_${player.id}`"
                      :style="getAvatarStyle(player)"
                  >
                    <div class="org-part__member-avatar"></div>
                    <div class="org-part__member-username">{{ player.name }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex xs12 mt">
              <div class="tile mt">
                <p class="text--teal text--orbitron">Über mich</p>
                <div v-html="record.about"></div>
              </div>
            </div>
            <div class="flex xs12 mt">
              <div class="tile mt">
                <p class="text--teal text--orbitron">Erwartungen</p>
                <div v-html="record.expectations"></div>
              </div>
            </div>
            <div class="flex xs12 mt">
              <div class="tile mt">
                <p class="text--teal text--orbitron">Fähigkeiten</p>
                <div v-html="record.skills"></div>
              </div>
            </div>
            <div class="flex xs12 mt">
              <div class="tile mt">
                <template v-if="originalRecord.state === 'open'">
                  <label>{{ __('entity.ingame.project.state') }}</label>
                  <single-select
                      :items="states"
                      v-model="record.state"
                      :append-icon="isChanged('state') ? 'pencil' : null"
                      @input="registerChange('state')"
                  />
                  <p>Begründung</p>
                  <ckeditor v-model="record.stateReason"
                            :title="'Begründung'"
                            :editor="editor"
                            :config="editorConfig"
                            @input="registerChange('stateReason')"></ckeditor>
                </template>
                <template v-else>
                  <p class="text--orbitron text--teal">{{ __('entity.ingame.project.state') }}</p>
                  <div v-html="renderStatus(record.state)"></div>
                  <p class="text--orbitron text--teal">Begründung</p>
                  <div v-html="record.stateReason"></div>
                </template>
              </div>
            </div>
            <div class="flex xs12 mt" v-if="originalRecord.state === 'open'">
              <div class="layout">
                <div class="flex xs6 mt">
                  <div class="btn btn--success" :loading="loading"
                       :disabled="changedProperties.length === 0"
                       @click="update" v-slashes>
                    Beantworten
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
import { localizationMixin } from '../../mixins/localization';
import { editFormMixin } from "../../mixins/editForm";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import apiSecured from "../../api/secured";

export default {
  props: ['role'],
  mixins: [localizationMixin, editFormMixin],
  data: () => ({
    getEndpoint: '/identity/roleapplication',
    endpoint: '/identity/roleapplication/resolve',
    emptyRecord: {
      id: null,
      state: 'open',
      stateReason: null,
    },
    editor: ClassicEditor,
    editorConfig: {
      toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList'],
      heading: {
        options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
        ]
      }
    },
    states: [],
    user: {},
  }),
  methods: {
    postProcess() {
      this.loading = true;
      apiSecured.get('/community/user/' + this.record.userId).then(async (res) => {
        this.user = res.data;
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error);
      });
    },
    getAvatarStyle(member) {
      return {
        'background-image': `url('${this.getAvatarUrl(member)}')`
      }
    },
    getAvatarUrl(member) {
      let avatarUrl = 'https://cdn.hyperion-corporation.de/ui/placeholder_avatar.png';
      if (member && member.avatar) {
        avatarUrl = member.avatar;
      }
      return avatarUrl;
    },
    renderStatus(value) {
      switch (value) {
        case 'open':
          return '<span class="ribbon info">offen</span>'

        case 'accepted':
          return '<span class="ribbon success">angenommen</span>'

        case 'declined':
          return '<span class="ribbon error">abgelehnt</span>'

        default:
          return '<span class="ribbon warning">value</span>'
      }
    }
  },
  created() {
    this.states = [
      { value: 'open', title: 'offen' },
      { value: 'accepted', title: 'angenommen' },
      { value: 'declined', title: 'abgelehnt' },
    ];
  }
}
</script>