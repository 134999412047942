<template>
  <div class="hyperion view">
    <app-header :title="'Hyperion'"/>
    <progress-linear v-if="loading"/>
    <div class="layout layout--center">
      <div class="flex xs12 sm6">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <template v-for="file in files">
              <div
                  class="swiper-slide"
                  :key="file"
              >
                <img :src="file" style="width: 100%;"/>
              </div>
            </template>
          </div>
          <div class="swiper-pagination"></div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>

        <div class="tile tile--height-auto mt bodytext">
          <h1>Willkommen bei <span class="text--primary">Hyperion</span></h1>
          <p>Hyperion bietet euch das größte deutschsprachige Netzwerk in Dual Universe von Handel, Missionen, Kopfgeldjagd bis zu Rettungsmissionen.</p>
          <p>Mit der Mitgliedschaft bei Hyperion erhältst du Zugang zu allen Einrichtungen von Hyperion, ein eigenes vQuanta Konto, Lehrgänge mit Betreuung und vieles mehr!</p>

          <h2>Was ist unser Ziel?</h2>
          <p>Mittelfristig möchten wir gemeinsam eine Heimat aufbauen, um weitere, spannende Projekte voranzutreiben. In mehreren Teams aufgeteilt, realisieren wir Raumschiffe, Stadtkonstrukte und diverse, andere Bauprojekte. Die Möglichkeiten für andere Geschäftszweige sind schier grenzenlos und somit liegt es auch an Dir, welchen Weg wir beschreiten.</p>

          <h2>Was bieten wir?</h2>
          <p>Eine freundliche Gemeinschaft deutschsprachiger Spieler und den Zugang zum Hyperion Netzwerk. Erhalte die Möglichkeit an großen und spannenden Projekten teilzunehmen. Hol dir Aufträge vom schwarzen Brett oder nutze unseren eigenen Markt. Bei uns kann sich jeder frei entfalten und seiner Kreativität freien Lauf lassen, solange es dem Wohl der Gemeinschaft nicht schadet und die Gesetze/Richtlinien von Hyperion, sowie auch der Ascendancy eingehalten werden. Des Weiteren bieten wir Sicherheit und Territorium durch die Ascendancy. Dies ist die größte Allianz im Spiel.</p>

          <h2>Was suchen wir?</h2>
          <p>Jeder deutschsprachige Spieler von Dual Universe der keine Augenklappe trägt und sich Pirat schimpfen lässt ist bei uns willkommen.</p>

          <h2>Wie bewerbe ich mich?</h2>
          <p>Klicke unten auf bewerben, fülle die Bewerbung aus und melde dich bei uns im <a href="https://discord.gg/5MMxacV" target="_blank">Discord</a> im Empfang. Ein beauftragter für Neulinge wird sich bei dir melden.</p>
          <p>Den Status deiner Bewerbung kannst du hier jederzeit einsehen.</p>
          <h3>Wir freuen uns darauf dich kennenzulernen.</h3>
        </div>

        <div class="tile tile--height-auto mt" v-if="role">
          <div class="text--orbitron text--teal">Bewerbungen</div>
          <role-applications :role="role" only-current-user="true"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiSecured from '../../api/secured';
import AppHeader from '../../components/AppHeader';
import { notificationsMixin } from '../../mixins/notifications';
import { localizationMixin } from '../../mixins/localization';
import OrgPart from "../../components/Community/OrgPart";
import { pageTitleMixin } from "../../mixins/pageTitle";
import EditRole from '../../components/Auth/EditRole';
import { userMixin } from "../../mixins/user";
import RoleApplications from "../../components/Community/RoleApplications";
import Swiper from "swiper";

export default {
  mixins: [notificationsMixin, localizationMixin, pageTitleMixin, userMixin],
  components: {
    OrgPart,
    AppHeader,
    EditRole,
    RoleApplications,
  },
  data: () => ({
    loading: false,
    role: [],
    files: [
      'https://cdn.hyperion-corporation.de/ui/Ei8yFsQWkAcM_nD.png',
      'https://cdn.hyperion-corporation.de/ui/EiX854oWsAMG8sR.png',
      'https://cdn.hyperion-corporation.de/ui/EiX89NFWAAQPTJR.png',
    ],
    swiperOptions: {
      autoHeight: false,
      effect: 'slide',
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      on: {
        slideChange: function () {
          Array.from(document.getElementsByClassName('swiper-slide-video')).forEach(video => {
            video.pause();
          })
        }
      }
    },
  }),
  methods: {
    async getMemberRole() {
      await apiSecured.get('/identity/role/4907798b-a11c-42ec-a188-a563ad77760f').then(async (res) => {
        this.role = res.data;
        this.loading = false;
        this.showInfoNotification(this.__('common.data_loaded'));
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error)
      });
    },
    renderBackgroundImage(file) {
      return { 'background-image': `url('${file}')` }
    },
  },
  async created() {
    this.setPageTitle('Hyperion');
    await this.getMemberRole();
    this.swiperInstance = new Swiper('.swiper-container', this.swiperOptions);
  }
}
</script>