<template>
  <div class="edit-role-application">
    <my-dialog :show="show" @close="close" max-width="800px">
      <div class="card">
        <div class="card__header">
          <div class="card__title">Bewerben bei {{ role ? role.name : '-' }}</div>
          <div class="card__button" v-slashes @click="close"><img
              src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/></div>
        </div>
        <div class="card__content" v-if="record">
          <progress-linear v-if="loading"/>
          <div class="tile" v-if="record.role && record.role.description" v-html="record.role.description ? record.role.description : '-- keine Beschreibung --'"></div>
          <div class="layout layout--wrap">
            <template v-if="originalRecord.state === 'open'">
              <div class="flex xs12 mt">
                <p>Über mich</p>
                <ckeditor v-model="record.about"
                          :title="'Über mich'"
                          :editor="editor"
                          :config="editorConfig"
                          @input="registerChange('about')"></ckeditor>
              </div>
              <div class="flex xs12 mt">
                <p>Erwartungen</p>
                <ckeditor v-model="record.expectations"
                          :title="'Erwartungen'"
                          :editor="editor"
                          :config="editorConfig"
                          @input="registerChange('expectations')"></ckeditor>
              </div>
              <div class="flex xs12 mt">
                <p>Fähigkeiten</p>
                <ckeditor v-model="record.skills"
                          :title="'Fähigkeiten'"
                          :editor="editor"
                          :config="editorConfig"
                          @input="registerChange('skills')"></ckeditor>
              </div>
              <div class="flex xs12">
                <div class="layout">
                  <div class="flex xs6 mt">
                    <div class="btn btn--success" :loading="loading"
                         :disabled="changedProperties.length === 0"
                         @click="update" v-slashes>
                      Bewerben
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="flex xs12 mt">
                <div class="tile mt">
                  <p class="text--teal text--orbitron">Über mich</p>
                  <div v-html="record.about"></div>
                </div>
              </div>
              <div class="flex xs12 mt">
                <div class="tile mt">
                  <p class="text--teal text--orbitron">Erwartungen</p>
                  <div v-html="record.expectations"></div>
                </div>
              </div>
              <div class="flex xs12 mt">
                <div class="tile mt">
                  <p class="text--teal text--orbitron">Fähigkeiten</p>
                  <div v-html="record.skills"></div>
                </div>
              </div>
              <div class="flex xs12 mt">
                <div class="tile mt">
                  <p class="text--orbitron text--teal">{{ __('entity.ingame.project.state') }}</p>
                  <div v-html="renderStatus(record.state)"></div>
                  <p class="text--orbitron text--teal">Begründung</p>
                  <div v-html="record.stateReason"></div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
import { localizationMixin } from '../../mixins/localization';
import { editFormMixin } from "../../mixins/editForm";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  props: ['role'],
  mixins: [localizationMixin, editFormMixin],
  data: () => ({
    closeAfterCreated: true,
    endpoint: '/identity/roleapplication',
    emptyRecord: {
      id: null,
      role: null,
      roleId: null,
      about: null,
      expectations: null,
      skills: null,
      state: 'open',
    },
    editor: ClassicEditor,
    editorConfig: {
      toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList'],
      heading: {
        options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
        ]
      }
    }
  }),
  methods: {
    renderStatus(value) {
      switch (value) {
        case 'open':
          return '<span class="ribbon info">offen</span>'

        case 'accepted':
          return '<span class="ribbon success">angenommen</span>'

        case 'declined':
          return '<span class="ribbon error">abgelehnt</span>'

        default:
          return '<span class="ribbon warning">value</span>'
      }
    }
  }
}
</script>

<style scoped>

</style>