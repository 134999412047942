<template>
  <div class="org-part">
    <div class="org-part__content" @click="openDialog">
      <div class="org-part__header">
        <div class="org-part__title">{{ orgPart.name }}</div>
        <div class="org-part__action" v-slashes @click="emitEditRole({role: orgPart, event: $event})"
             v-if="hasOneRoles([])">
          <icon :icon="'pencil'"/>
        </div>
        <div class="org-part__action" v-slashes @click="emitDeleteRole({role: orgPart, event: $event})"
             v-if="hasOneRoles([])">
          <icon :icon="'bin'"/>
        </div>
      </div>
      <div class="org-part__leader" v-if="orgPart.prime">
        <div class="org-part__leader-avatar" :style="getAvatarStyle(orgPart.prime)"></div>
        <div class="org-part__leader-username">{{ orgPart.prime.userName }}</div>
      </div>
      <div class="org-part__leader" v-if="orgPart.vice">
        <div class="org-part__leader-avatar" :style="getAvatarStyle(orgPart.vice)"></div>
        <div class="org-part__leader-username">{{ orgPart.vice.userName }}</div>
      </div>
      <div class="org-part__members-btn">
        <div class="org-part__members-btn-count">{{ orgPart.members.length }}</div>
        <div class="org-part__members-btn-label">members</div>
      </div>
    </div>

    <my-dialog :show="showDialog" @close="closeDialog">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{ orgPart.name }}</div>
          <div class="card__button" @click="closeDialog" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content">
          <progress-linear v-if="loading"/>
          <div class="tile">
            <div class="layout layout--center">
              <div class="flex" style="max-width: 800px;">
                <div class=" bodytext bodytext--larger">
                  <p v-html="orgPart.description && orgPart.description.trim().length > 0 ? orgPart.description : '-- keine Beschreibung --'"></p>
                </div>
              </div>
            </div>
          </div>

          <div class="tile flex-only mt">
            <div>
              <div class="text--orbitron text--teal text--center">Leiter</div>
              <div
                  class="org-part__member org-part__member--no-action"
                  :style="getAvatarStyle(orgPart.prime)"
              >
                <div class="org-part__member-avatar"></div>
                <div class="org-part__member-username" v-if="orgPart.prime">{{ orgPart.prime.userName }}</div>
                <div class="org-part__member-username text-error" v-else>unbesetzt</div>
              </div>
            </div>
            <div>
              <div class="text--orbitron text--teal text--center">Stellvertreter</div>
              <div
                  class="org-part__member org-part__member--no-action"
                  :style="getAvatarStyle(orgPart.vice)"
              >
                <div class="org-part__member-avatar"></div>
                <div class="org-part__member-username" v-if="orgPart.vice">{{ orgPart.vice.userName }}</div>
                <div class="org-part__member-username text--error" v-else>unbesetzt</div>
              </div>
            </div>
            <div class="flex-only__grow">
            </div>
          </div>

          <div class="tile mt">
            <div class="text--orbitron text--teal">Mitglieder</div>
            <div class="control-bar" :class="{'control-bar--disabled': loading}">
              <div class="control-bar__info">
                <div class="control-bar__info-title">Total:</div>
                <div class="control-bar__info-value">{{ orgPart.members.length }}</div>
              </div>
              <text-field
                  class="control-bar__search"
                  v-model="searchTerm"
                  :label="__('common.search_term')"
                  max-length="50"
                  :hide-details="true"
                  :clearable="true"
              />

              <div class="control-bar__buttons">
                <div class="control-bar__button control-bar__button--with-text" :title="'Einladen'"
                     v-if="!noActions && editRights"
                     @click="browseUsers()"
                     v-slashes>
                  <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/add.svg"
                       alt="bewerben"/>
                  Hinzufügen
                </div>
                <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
                  <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
                       alt="refresh"/>
                </div>
              </div>
            </div>
            <div class="org-part__members mt" v-if="orgPart.members && orgPart.members.length">
              <div
                  class="org-part__member"
                  :class="{'org-part__member--no-action': noActions || !editRights, 'org-part__member--disabled': member.lockoutEnabled}"
                  v-for="member in filteredMembers"
                  :key="`org-part-member_${orgPart.id}_${member.id}`"
                  :style="getAvatarStyle(member)"
                  v-slashes
                  @click="removeMemberFromOrgPart({orgPart, member})"
              >
                <div class="org-part__member-avatar"></div>
                <div class="org-part__member-username">{{ member.userName }}</div>
                <div class="org-part__action" v-if="!noActions && editRights">
                  <icon :icon="'bin'"/>
                </div>
              </div>
            </div>
          </div>

          <div class="tile mt" v-if="showDialog">
            <div class="text--orbitron text--teal">Bewerbungen</div>
            <role-applications :role="orgPart" @resolved="refresh"/>
          </div>

        </div>
      </div>
    </my-dialog>

    <div class="org-part__children" :class="{'org-part__children--vertical': depth > 1}">
      <org-part
          :org-part="child"
          v-for="child in orgPart.children"
          :id="`org-part_${child.id}`"
          :key="`org-part_${child.id}`"
          :depth="depth + 1"
          @editRole="emitEditRole"
          @deleteRole="emitDeleteRole"
      />
    </div>
    <universal-browser
        ref="userBrowser"
        :endpoint="'/community/user'"
        :field-title="'userName'"
        :title="__('entity.common.users')"
        @itemSelected="handleUserSelected"
    />
  </div>
</template>

<script>
import { userMixin } from "../../mixins/user";
import { localizationMixin } from "../../mixins/localization";
import EditRoleApplication from "./EditRoleApplication";
import RoleApplications from "./RoleApplications";
import Swal from "sweetalert2";
import apiSecured from "../../api/secured";
import { notificationsMixin } from "../../mixins/notifications";

export default {
  components: { RoleApplications, EditRoleApplication },
  mixins: [userMixin, localizationMixin, notificationsMixin],
  name: "OrgPart",
  props: ['orgPart', 'noActions', 'depth'],
  data: () => ({
    showUserBrowser: false,
    showDialog: false,
    searchTerm: null,
    loading: false,
  }),
  computed: {
    filteredMembers() {
      if (!this.searchTerm || this.searchTerm.trim().length < 1) {
        return this.orgPart.members;
      }
      return this.orgPart.members.filter(member => member.userName.toLowerCase().indexOf(this.searchTerm.trim().toLowerCase()) > -1);
    },
    editRights() {
      const isAdmin = this.hasOneRoles([]);
      const isPrime = this.orgPart.primeId && this.orgPart.primeId === this.$store.state.user.id;
      const isVice = this.orgPart.viceId && this.orgPart.viceId === this.$store.state.user.id;
      return isAdmin || isPrime || isVice;
    }
  },
  methods: {
    openDialog(event) {
      event.stopPropagation();
      this.showDialog = true;
    },
    closeDialog(event) {
      event.stopPropagation();
      this.showDialog = false;
    },
    emitEditRole({ role, event }) {
      event.stopPropagation();
      this.$emit('editRole', { role, event });
    },
    emitDeleteRole({ role, event }) {
      event.stopPropagation();
      this.$emit('deleteRole', { role, event });
    },
    browseUsers() {
      this.showUserBrowser = true;
      setTimeout(() => {
        this.$refs.userBrowser.openDialog();
      }, 100)
    },
    handleUserSelected(user) {
      this.addMemberToOrgPart({ orgPart: this.orgPart, member: user });
      this.showUserBrowser = false;
    },
    getAvatarStyle(member) {
      return {
        'background-image': `url('${this.getAvatarUrl(member)}')`
      }
    },
    getAvatarUrl(member) {
      let avatarUrl = 'https://cdn.hyperion-corporation.de/ui/placeholder_avatar.png';
      if (member && member.avatar) {
        avatarUrl = member.avatar;
      }
      return avatarUrl;
    },
    async addMemberToOrgPart({ orgPart, member }) {
      await Swal.fire({
        title: this.__('common.are_you_sure'),
        html: this.__('common.add_user_to_role_question').replace('$user', member.userName).replace('$role', orgPart.name),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!'
      }).then(async (result) => {
        if (result.value) {
          let payload = {
            roleId: orgPart.id
          };
          this.loading = true;
          let endpoint = `/identity/user/${member.id}/assign-user-to-role`;
          await apiSecured.patch(endpoint, payload).then((res) => {
            this.loading = false;
            this.showSuccessNotification(this.__('common.saved'));
            this.refresh();
          }).catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error);
          });
        }
      });
    },
    async removeMemberFromOrgPart({ orgPart, member }) {
      await Swal.fire({
        title: this.__('common.are_you_sure'),
        html: this.__('common.remove_user_from_role_question').replace('$user', member.userName).replace('$role', orgPart.name),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!'
      }).then(async (result) => {
        if (result.value) {
          let payload = {
            roleId: orgPart.id,
          };
          this.loading = true;
          let endpoint = `/identity/user/${member.id}/remove-user-from-role`;
          await apiSecured.patch(endpoint, payload).then((res) => {
            this.loading = false;
            this.showSuccessNotification(this.__('common.saved'));
            this.refresh();
          }).catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error);
          });
        }
      });
    },
    refresh() {
      this.loading = true;
      const params = {
        pageSize: -1,
        orderBy: 'userName',
        roleName: this.orgPart.name
      }
      apiSecured.get('/community/user', { params }).then(async (res) => {
        this.orgPart.members = res.data;
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error);
      });
    },
  },
}
</script>