<template>
  <div class="edit-role">
    <my-dialog :show="show" @close="close" max-width="800px">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{ __('component.auth.edit_role.title') }}</div>
          <div class="card__button" v-slashes @click="close"><img
              src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/></div>
        </div>
        <div class="card__content" v-if="record">
          <progress-linear v-if="loading"/>
          <div class="layout layout--wrap">
            <div class="flex xs12">
              <div class="layout layout--wrap">
                <div class="flex xs12 mt">
                  <text-field
                      v-model="record.name"
                      :label="__('entity.common.name')"
                      :error-messages="errors.name"
                      maxlength="50"
                      :prepend-icon="isChanged('name') ? 'pencil' : null"
                      @input="registerChange('name')"
                  />
                </div>
                <div class="flex xs12">
                  <text-area
                      v-model="record.description"
                      :label="__('entity.common.description')"
                      :error-messages="errors.description"
                      :prepend-icon="isChanged('description') ? 'pencil' : null"
                      @input="registerChange('description')"
                  />
                </div>
                <div class="flex xs12">
                  <label>{{ __('entity.auth.role.parent') }}</label>
                  <select-reference
                      v-model="record.parentId"
                      :endpoint="'/identity/role'"
                      :field-title="'name'"
                      :nothing-selected-message="__('component.auth.edit_role.no_parent')"
                      :prepend-icon="isChanged('parentId') ? 'pencil' : ''"
                      @input="registerChange('parentId')"
                  />
                </div>
                <div class="flex xs12">
                  <label>{{ __('entity.common.prime') }}</label>
                  <select-reference
                      v-model="record.primeId"
                      :endpoint="'/identity/user'"
                      :field-title="'userName'"
                      :nothing-selected-message="__('common.no_user_selected')"
                      :prepend-icon="isChanged('primeId') ? 'pencil' : ''"
                      @input="registerChange('primeId')"
                  />
                </div>
                <div class="flex xs12">
                  <label>{{ __('entity.common.vice') }}</label>
                  <select-reference
                      v-model="record.viceId"
                      :endpoint="'/identity/user'"
                      :field-title="'userName'"
                      :nothing-selected-message="__('common.no_user_selected')"
                      :prepend-icon="isChanged('viceId') ? 'pencil' : ''"
                      @input="registerChange('viceId')"
                  />
                </div>
                <div class="flex xs12 mt">
                  <text-field
                      v-model="record.gitlabGroupIds"
                      :label="__('entity.auth.role.gitlab_group_ids')"
                      :error-messages="errors.gitlabGroupIds"
                      maxlength="50"
                      :prepend-icon="isChanged('gitlabGroupIds') ? 'pencil' : null"
                      @input="registerChange('gitlabGroupIds')"
                  />
                </div>
                <div class="flex xs6 mt">
                  <div class="btn btn--success" :loading="loading" :disabled="changedProperties.length === 0"
                       @click="update" v-slashes>{{ __('common.save_changes') }}
                  </div>
                </div>
                <div class="flex xs6 mt">
                  <div class="btn btn--primary" @click="close" v-slashes>{{ __('common.close') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
import { localizationMixin } from '../../mixins/localization';
import { editFormMixin } from "../../mixins/editForm";

export default {
  mixins: [localizationMixin, editFormMixin],
  data: () => ({
    endpoint: '/identity/role',
    emptyRecord: {
      id: null,
      name: '',
      description: null,
      parentId: null,
      primeId: null,
      viceId: null,
      gitlabRoleIds: '',
      createdAt: '---',
      updatedAt: '---'
    },
  }),
}
</script>