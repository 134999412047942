<template>
  <div class="hyperion view">
    <app-header :title="'Hyperion'"/>
    <progress-linear v-if="loading"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.add')" @click="addRole" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/add.svg"
               alt="add"/>
        </div>
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
      </div>
    </div>
    <div class="org-tree mt">
      <org-part
          v-if="hyperion"
          :org-part="hyperion"
          :depth="0"
          @editRole="editRole"
          @deleteRole="deleteRole"
      />
    </div>
    <div class="org-grid">
      <org-part
          v-for="orgPart in nonStructureOrgParts"
          :org-part="orgPart"
          :key="`org-part_${orgPart.id}`"
          @editRole="editRole"
          @deleteRole="deleteRole"
      />
    </div>
    <edit-role ref="editRole" @updated="refresh" @created="editRole"/>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import apiSecured from '../../api/secured';
import AppHeader from '../../components/AppHeader';
import { notificationsMixin } from '../../mixins/notifications';
import { localizationMixin } from '../../mixins/localization';
import OrgPart from "../../components/Community/OrgPart";
import { pageTitleMixin } from "../../mixins/pageTitle";
import EditRole from '../../components/Auth/EditRole';
import { userMixin } from "../../mixins/user";

export default {
  mixins: [notificationsMixin, localizationMixin, pageTitleMixin, userMixin],
  components: {
    OrgPart,
    AppHeader,
    EditRole,
  },
  data: () => ({
    loading: false,
    roles: [],
    users: [],
    hyperion: null,
    nonStructureOrgParts: [],
  }),
  methods: {
    addRole() {
      this.$refs.editRole.showCreate();
    },
    editRole({role}) {
      this.$refs.editRole.showEdit(role);
    },
    deleteRole({role}) {
      Swal.fire({
        title: this.__('common.are_you_sure'),
        text: this.__('common.do_you_want_to_delete'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.__('common.yes_delete_it')
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          let endpoint = '/identity/role/' + role.id;
          apiSecured.delete(endpoint).then((res) => {
            this.loading = false;
            this.showSuccessNotification(this.__('common.deleted'));
            this.refresh();
          }).catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error);
            this.refresh();
          });
        }
      });
    },
    async refresh() {
      this.loading = true;
      await apiSecured.get('/community/hyperion').then(async (res) => {
        this.hyperion = res.data.hyperion;
        this.nonStructureOrgParts = res.data.nonStructureOrgParts;
        this.loading = false;
        this.showInfoNotification(this.__('common.data_loaded'));
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error)
      });

    },
  },
  created() {
    this.setPageTitle('Hyperion');
    this.refresh();
  }
}
</script>